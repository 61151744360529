









































































































































































@import "~@/assets/styles/components/formularios";
@import "~@/assets/styles/components/modal-cadastro";

.modal-cadastro-titulo {
  justify-content: center;
  text-align: center;
  line-height: 1.4;
  font-size: 14px;
}
